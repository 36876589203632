import React from "react";

const Home3 = () => {
  return (
    <div className="hc3-d1">
      <div   data-aos-duration="2000"
         data-aos="zoom-in" className="hc3-d2">
        <div className="lh-hc3">
          <h1 className="lh-hc3-h1">
            28 DAYS <br /> TRANSFORMATION <br /> PROGRAM
          </h1>
          <h1 className="lh-hc3-h2">PROGRAM SPECIFICATION</h1>
          <div>
            <h1 className="lh-hc3-h3">
              - Customised Program
              <br />
              - Custom Meal Plan
              <br />- Motivation & Mindset Support
              <br />- WhatsApp Support through Program
            </h1>
          </div>
          <button className="btn-hc3-1">Start Now!!</button>
        </div>
        <div className="rh-hc3">
          <img src={require("../../assets/ruh3.jpg")} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home3;
