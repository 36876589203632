import React from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const ImageLightbox = () => {
  // Corrected the array to have different images
  const images = [
    require("../../assets/transformations/7ba4ba06-b2bc-4eb6-9dc0-3c52afad9382.jpeg"),
    require("../../assets/transformations/98d536f4-38ff-4675-969c-cebef982ff7a.jpeg"),
    require("../../assets/transformations/6b0df3ca-37c5-4f8b-b95d-017a380ec3d1.jpeg"),
    require("../../assets/transformations/68accc0a-6e7c-47f6-ad19-ce7c16933487.jpeg"),
    require("../../assets/transformations/539bfe15-ad49-41ad-896f-576b1ae2f100.jpeg"),
    require("../../assets/transformations/9bf85d8f-04dd-4a4f-8af9-691ebf1ae5d7.jpeg"),
    require("../../assets/transformations/a7307bfc-af9c-48e3-bf57-a0a6bff8dfdc.jpeg"),
    require("../../assets/transformations/bbad9a0b-13e1-409c-abf7-f3cce28c80a1.jpeg"),
    require("../../assets/transformations/f4b49c0f-ae4b-4ae3-b3fe-7d8331540ec1.jpeg"),








    



  ];

  return (
    <div className="slide-box-section">
      <div>
        <SlideshowLightbox className="img-testslide">
          {images.map((img, index) => (
            <img
              key={index}
              className="test-img"
              src={img}
              alt={`Image ${index}`}
            />
          ))}
        </SlideshowLightbox>
      </div>
    </div>
  );
};

export default ImageLightbox;
