import React from "react";
import "./Home.css";

import Home1 from "../../Components/HomeComponent/Home1";
import Home2 from "../../Components/HomeComponent/Home2";
import Home3 from "../../Components/HomeComponent/Home3";
import Home4 from "../../Components/HomeComponent/Home4";
import Home5 from "../../Components/HomeComponent/Home5";
import Home6 from "../../Components/HomeComponent/Home6";
import Home7 from "../../Components/HomeComponent/Home7";
import Home8 from "../../Components/HomeComponent/Home8";
const Home = () => {


  
  return (
    <>
      <Home1 />
      <Home2 />
      <Home3 />
      <Home4 />
      <Home5 />
      <Home6/>
      <Home7/>
      <Home8/>





    </>
  );
};

export default Home;
