import React from "react";
import "./HomeComponent.css";
const Home2 = () => {
  return (
    <div className="hc2-d1">
      <div className="hc2-d2">
        <div className="hc2-d4">
          <div
          data-aos-duration="1000"
          
          data-aos="fade-right"
          className="hc2-d3">
            <img src={require("../../assets/icons/h1-svg-1 (1).png")} alt="" />

            <h1 className="hc2-h1">Progression</h1>
          </div>

          <div 
          data-aos-duration="1000"

          data-aos="fade-right"
          className="hc2-d3">
            <img src={require("../../assets/icons/h3-icon-2 (1).png")} alt="" />

            <h1 className="hc2-h1">Workout</h1>
          </div>

          <div 
          data-aos-duration="1000"

          data-aos="fade-left"
          className="hc2-d3">
            <img src={require("../../assets/icons/h3-icon-3 (1).png")} alt="" />

            <h1 className="hc2-h1">Nutrition</h1>
          </div>

          <div 
          data-aos-duration="1000"
          data-aos="fade-left"
          className="hc2-d3">
            <img src={require("../../assets/icons/h3-icon-4 (1).png")} alt="" />

            <h1 className="hc2-h1">Confidence</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home2;
