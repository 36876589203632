import React from "react";
import ImageLightbox from "./ImageLightbox";

const Home7 = () => {
  return (
    <div className="hc-7-2">
        <div >
        <h1 className="hc-6-h1">Client Testimonials</h1>
        <div className="test-d1-hc7">
          <div 
          
          data-aos-duration="2000" data-aos="zoom-in"
          className="test-d2-hc7">
            <ImageLightbox />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home7;
