import React from "react";
import "./HomeComponent.css";
import ReactWhatsapp from "react-whatsapp";
const Home1 = () => {
  return (
    <div className="home-d1">
      <div className="home-d-2">
        <div
          data-aos-duration="1000"
          data-aos="fade-left"
          data-aos-offset="300"
          className="home-d-4"
        >
          <h1 className="home-c-h1">
            Time To Bring
            <br />
            Your A-Game
          </h1>
        </div>
        <div className="home-d-3" data-aos-duration="2000" data-aos="zoom-in">
          <h1>RUH Tyagi</h1>
          <h1>Online Transformation Program</h1>
        </div>

        <ReactWhatsapp
          number={"+91 75007 52031"}
          message={"Hello Ruh Tyagi Im Intrested With your Coching Program "}
          data-aos-duration="1000"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          className="custom-btn btn-4"
        >
       JOIN NOW
        </ReactWhatsapp>

  
      </div>
    </div>
  );
};

export default Home1;
