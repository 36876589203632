import React from "react";
import "./Navbar.css";

const Navbar = () => {
  return (
    <div className="div-nav-1">
      <div className="div-nav2">
        <div className="nav-logo-div">
          <h1 className="logo-nav-h1">Ruh Tyagi</h1>
        </div>
        <div className="nav-links">
          <ul className="nav-ul1">
            <li>Home</li>
            <li>About</li>
            <li>Contact</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
