import React, { useState } from "react";
import "./HomeComponent.css";
import { FaCheck } from "react-icons/fa6";
import ReactWhatsapp from "react-whatsapp";
const Home5 = () => {
  const plans = [
    {
      id: 1,
      name: "Lifestyle fitness program",
      price: "₹5000/Month",

      duration: "Monthly",
      image:
        "https://scontent.cdninstagram.com/v/t39.30808-6/379256083_18065475397428729_1595088316602578809_n.jpg?stp=dst-jpg_e15&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xMDY2eDEzMzIuc2RyIn0&_nc_ht=scontent.cdninstagram.com&_nc_cat=109&_nc_ohc=yvUnR2hclwEAX-auz6z&edm=APs17CUAAAAA&ccb=7-5&ig_cache_key=MzE5Mjk0NTc0ODI3OTA3NDA2OQ%3D%3D.2-ccb7-5&oh=00_AfAfBoIyW0WRgyrZmNet9bmLsggHTyE7IXepKVxk1J241w&oe=6589D437&_nc_sid=10d13b",

      features1:
        "A LIFESTYLE PROGRAM FOR THOSE WAITING TO ACHIEVE THEIR PERSONAL FITNESS & PHYSIQUE GOALS.",
      features2: "Easy-To-Follow Workout Videos",
      message: "Lifestyle fitness program",
    },
    {
      id: 2,
      name: "Male fitness competition prep",
      price: "₹5000/Month",

      duration: "Monthly",
      image:
        "https://scontent.cdninstagram.com/v/t39.30808-6/405430033_18074202883428729_3946927308281018665_n.jpg?stp=dst-jpg_e15&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE0NDAuc2RyIn0&_nc_ht=scontent.cdninstagram.com&_nc_cat=109&_nc_ohc=GRP2np7ZkQgAX_lisOi&edm=APs17CUAAAAA&ccb=7-5&ig_cache_key=MzI0MzI2NDI3NTI4ODUwNzI2NQ%3D%3D.2-ccb7-5&oh=00_AfDUwEZl1cJk-KaUHcNwHujgo--AfJRWjgGRBdNGyaANuw&oe=658C125F&_nc_sid=10d13b",
      features1:
        "A LIFESTYLE PROGRAM FOR THOSE WAITING TO ACHIEVE THEIR PERSONAL FITNESS & PHYSIQUE GOALS.",

      features2: "Easy-To-Follow Workout Videos",
      message: "Male fitness competition prep",
    },
    {
      id: 3,
      name: "Female fitness competition prep",
      price: "₹5000/Month",

      duration: "Monthly",
      image: require("../../assets/female.jpeg"),
      features1:
        "A LIFESTYLE PROGRAM FOR THOSE WAITING TO ACHIEVE THEIR PERSONAL FITNESS & PHYSIQUE GOALS.",

      features2: "Easy-To-Follow Workout Videos",
      message: "Female fitness competition prep",
    },
  ];

  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  return (
    <div className="hc5-1">
      {/* <div className="content">
        <h1 className="title">React Whatsapp</h1>
        <section className="library">
          <input
            id="number"
            placeholder="Number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          <input
            id="message"
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <ReactWhatsapp
            number={"+91 75007 52031"}
            message={"hello how are you"}
          >
            Open Whatsapp
          </ReactWhatsapp>
        </section>
      </div> */}

      <div className="hc5-2">
        <div className="hc5-3">
          <h1 className="hc5-h1">TRANSFORMATION PROGRAMS</h1>
          <h1 className="hc5-h2">Choose Your Plan</h1>

          <div
            className="pricing-card-hc5   "
            data-aos-duration="2000"
            data-aos="zoom-in"
          >
            {plans.map((item) => (
              <>
                <div className="card-price-1">
                  <img className="card-price-img" src={item.image} alt="" />

                  {/* <div>
                    <h2>{item.price}</h2>
                    <h2>{item.duration}</h2>
                  </div> */}

                  <div className="card-price-d3">
                    <div className="card-price-d6">
                      <h1 className="plan-card-head">{item.name}</h1>
                    </div>
                    <div className="cards-points">
                      <h1>{item.features1}</h1>
                    </div>

                    <div className="btn-d1-jn">
                      <ReactWhatsapp
                        number={"+91 75007 52031"}
                        message={item.name}
                        className="jn-btn-price"
                      >
                        know More
                      </ReactWhatsapp>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home5;
